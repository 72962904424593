import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Offcanvas, Nav, Navbar, Button } from "react-bootstrap";
import MenuIcon from "../assets/images/menu-icon.png";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import NotificationIcon from "../assets/images/notificationIcon.svg";
import UserIcon from "../assets/images/user-icon.svg";
import BalanceIcon from "../assets/images/balance-icon.png";
import CashierIconNew from "../assets/images/Plus.png";
import WalletIcon from "../assets/images/WalletIcon.png";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import DepositIcon1 from "../assets/images/deposit.png";
import WithdrawIcon1 from "../assets/images/money-withdrawal.png";
import AcSettings from "../assets/images/AcSettings.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";

import { FaRegMoneyBillAlt } from "react-icons/fa";
import { MdOutlineVerified } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { AiOutlineFileDone } from "react-icons/ai";
import LogoutIconImg from "../assets/images/logout.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Link } from "react-router-dom";
import logo from "../assets/images/mmplay.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import NewsIcon from "../assets/images/news-icon.png";
import MobLeftbar from "./MobLeftbar";
import { AiOutlineSearch } from "react-icons/ai";
import GamesSearchModal from "../views/components/GamesSearchModal";
import numberWithCommas from "../utils/numbersWithComma";

import DepositIcon from "../assets/images/icons/payment.png";
import WithdrawIcon from "../assets/images/icons/cash-withdrawal.png";

import SpeakerIcon from "../assets/images/icons/megaphone.png";

import AvitorImg from "../assets/images/icons/aviator.png";

import HomeICon from "../assets/images/icons/sidebar/Home.png";
import Inplay from "../assets/images/icons/sidebar/InPlay.png";
import Cricket from "../assets/images/icons/sidebar/Cricket.png";
import Soccer from "../assets/images/icons/sidebar/Soccer.png";
import Tennis from "../assets/images/icons/sidebar/Tennis.png";
import Horse from "../assets/images/icons/sidebar/Horse.png";
import Greyhound from "../assets/images/icons/sidebar/greyhound.png";
import EvolutionIcon from "../assets/images/icons/sidebar/Evolution.png";
import LCImg from "../assets/images/icons/sidebar/LiveCasino.png";
import QtechImg from "../assets/images/icons/sidebar/Qtech.png";
import QtechImg1 from "../assets/images/icons/sidebar/Qtech1.png";

import SupernowaImg from "../assets/images/icons/sidebar/Supernowa.png";
import WCImg from "../assets/images/icons/sidebar/WorldCasino.png";
import SlotsImg from "../assets/images/icons/sidebar/Slot.png";
import EvolutionImg from "../assets/images/icons/sidebar/Evolution.png";
import EzugiImg from "../assets/images/icons/sidebar/Ezugi.png";

import Cross from "../assets/images/icons/close.png";
import { handleLeftSideBar, handleRightSideBar } from "../redux/app/actions";
import Sports from "../assets/images/icons/sport.png";

import ProfileIcon1 from "../assets/images/icons/usernew.png";

const HeaderAfterLogin = () => {
  const { user, balance, wallet } = useSelector((state) => state.auth);

  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => dispatch(handleRightSideBar(!showRightSideBar));
  const handleShow = () => setShow(true);
  const handleShow1 = () => dispatch(handleLeftSideBar(!showLeftSideBar));
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const { showRightSideBar } = useSelector((state) => state.app);
  const { showLeftSideBar } = useSelector((state) => state.app);

  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  // Date and Time Start ======>>>>>>
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  // <<<<<<====== Date and Time End

  const location = useLocation();
  // console.log("Pathhh",location.pathname)
  const [isBottomHead, setIsBottomHead] = useState(true);
  useEffect(() => {
    if (location.pathname.startsWith("/spmsports/")) {
      setIsBottomHead(false);
    } else {
      setIsBottomHead(true);
    }
  }, [isBottomHead]);

  const headerItems = [
    {
      id: 1,
      name: "Home",
      key: "home",
      redirectionUrl: "/sports",
      image: HomeICon,
    },
    {
      id: 2,
      name: "In-Play",
      key: "inplay",
      redirectionUrl: "/sportsbook/Inplay",
      image: Inplay,
    },
    {
      id: 3,
      name: "Cricket",
      key: "cricket",
      redirectionUrl: "/sportsbook/Cricket",
      image: Cricket,
    },
    {
      id: 16,
      name: "Sports",
      key: "sports",
      redirectionUrl: "/betby",
      image: Sports,
    },
    {
      id: 4,
      name: "Soccer",
      key: "soccer",
      redirectionUrl: "/sportsbook/Soccer",
      image: Soccer,
    },
    {
      id: 5,
      name: "Tennis",
      key: "Tennis",
      redirectionUrl: "/sportsbook/Tennis",
      image: Tennis,
    },
    {
      id: 6,
      name: "Horse",
      key: "horse",
      redirectionUrl: "/sportsbook/Inplay",
      image: Horse,
    },
    {
      id: 7,
      name: "Greyhound",
      key: "greyhound",
      redirectionUrl: "/sportsbook/Greyhound%20Racing",
      image: Greyhound,
    },
    {
      id: 8,
      name: "Aviator",
      key: "aviator",
      redirectionUrl: "/casino/spribe/aviator",
      image: AvitorImg,
    },
    {
      id: 9,
      name: "Evolution",
      key: "evolution",
      redirectionUrl: "/casino/evolution",
      image: EvolutionImg,
    },
    {
      id: 10,
      name: "Live Casino",
      key: "liveCasino",
      redirectionUrl: "/Casino",
      image: LCImg,
    },
    {
      id: 11,
      name: "Qtech",
      key: "qtech",
      redirectionUrl: "/casino/qtech",
      image: QtechImg1,
    },
    {
      id: 12,
      name: "Supernowa",
      key: "supernowa",
      redirectionUrl: "/casino/supernowa",
      image: SupernowaImg,
    },
    {
      id: 13,
      name: "World Casino",
      key: "worldCasino",
      redirectionUrl: "/casino/worldcasino",
      image: WCImg,
    },
    {
      id: 14,
      name: "Slots",
      key: "slots",
      redirectionUrl: "/Slots",
      image: SlotsImg,
    },
    {
      id: 15,
      name: "Ezugi",
      key: "ezugi",
      redirectionUrl: "/casino/ezugi",
      image: EzugiImg,
    },
  ];

  // console.log("balance", wallet);

  const checkIfActive = (key) => {
    return key === location?.state?.activeKey;
  };

  return (
    <>
      <header className="header afterlogin">
        <div className="MarqueeSection">
          <img src={SpeakerIcon} alt="speaker" />
          <marquee>
            Enjoy Unmatched Betting Excitement and Access 500+ Casino and Online
            Games
          </marquee>
          {/* <span>25 Dec 2023 11:17:10</span> */}
          <span>
            {formattedDate} {formattedTime}
          </span>
        </div>
        <div className="topHead">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="primary menu-btn"
                    onClick={handleShow1}
                    className="d-block d-md-none"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </Button>

                  <div className="logo">
                    <a href="/sports">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <div className="header-left mx-auto d-none  d-none">
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      className="d-none"
                    />
                    <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                      <Nav className="me-auto">
                        <Nav.Link
                          href="/sportsbook"
                          className={checkIfActive("home") ? "active" : ""}
                        >
                          Home
                        </Nav.Link>
                        <Nav.Link
                          href="/sportsbook"
                          className={checkIfActive("inplay") ? "active" : ""}
                        >
                          Sportsbook
                        </Nav.Link>
                        <Nav.Link href="/live-dealer">Live Dealers</Nav.Link>

                        <Nav.Link
                          href="/casino/spribe/aviator"
                          // className={checkIfActive(item?.key) ? "active" : ""}
                        >
                          Aviator
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </div>

                  <div className="headerRight ms-auto ms-xl-0">
                    <ul>
                      <li className="balance">
                        {" "}
                        <div className="balance-amount" href="/cashier">
                          {/* <img src={BalanceIcon} alt="Balance Icon" width={24} /> */}
                          <a href="/withdraw" className="WithDrawHeader">
                            W
                          </a>
                          <div className="d-sm-flex align-items-sm-center">
                            <div className="">
                              Cash: {numberWithCommas(wallet?.cash)}
                            </div>
                            <div className="bonus">
                              Bonus: {numberWithCommas(wallet?.coins)}
                            </div>

                            <div className="balance d-flex align-items-center">
                              <span>Balance:&nbsp;</span>
                              <span>{wallet?.balance}</span>
                            </div>
                          </div>
                          <a href="/gatewaylist" className="WithDrawHeader">
                            D
                          </a>
                          {/* <a href="/gatewaylist" className="deposit-header">
                        <img
                          src={CashierIconNew}
                          alt="Balance Icon"
                          width={24}
                        />
                      </a> */}
                        </div>
                      </li>
                      <li className="d-none">
                        <GamesSearchModal />
                      </li>
                      <li className="head-social">
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIcon} alt="telegram" />
                        </a>
                      </li>

                      <li className="d-none">
                        {/* <a
                          className="head-social"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "#"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon} alt="whatsapp" />
                        </a> */}
                      </li>
                      {/* <div className="searchbar">
                    <AiOutlineSearch />
                  </div> */}
                    </ul>

                    <Button
                      variant="primary menu-btn"
                      onClick={() => {
                        dispatch(handleRightSideBar(!showRightSideBar));
                        // console.log("SideBar", showRightSideBar);
                      }}
                      className="d-none d-md-block"
                    >
                      <img src={MenuIcon} alt="User Menu Icon" width={25} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="announcementbar d-none">
            <span className="news">
              Announcement
              <img src={NewsIcon} alt="news" width={20} />
            </span>
            <marquee scrollamount="3">
              hfdjkshh xjlkvlkvj klsjfkls jdflkj sldkfjlk jdflkjd flkjsklfd
              jjkdflksj dflkjsdklf jlsj flksjdf
            </marquee>
          </div>
        </div>

        <Offcanvas
          className="user_section"
          show={showRightSideBar}
          onHide={handleClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-md-flex d-none">
                <div className="profile-img">
                  <img src={UserIcon} alt="User Icon" />
                </div>
                <div className="user-info">
                  <a href="/profile" className="user_name">
                    {user?.mstruserid}
                  </a>
                  <p>{user?.mobileno}</p>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container upperbalnace d-md-flex d-none">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <img src={WalletIcon} alt="wallet" />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    {" "}
                    INR {wallet?.balance}
                  </div>
                </div>
                {/* <div className="col-12">
                <div className="non_cash_balance">
                  <h6 className="non_cash_balance_heading">
                    <span>
                      <strong> Bonus</strong> <small>Non-Cash-Balance </small>{" "}
                    </span>
                    <span>
                      <strong>{wallet?.coins}</strong>
                    </span>
                  </h6>
                </div>
              </div> */}
                <div className="col-6">
                  <a className="btn_withdraw w-100" href="/withdraw">
                    Withdraw
                  </a>
                </div>
                <div className="col-6">
                  <a className="btn_primary w-100" href="/gatewaylist">
                    Deposit
                  </a>
                </div>
              </div>
            </div>

            <ul className="menu-items">
              <div className="d-md-none d-flex justify-content-between HeadingImg align-items-center">
                <h6>My Account</h6>
                <img
                  src={Cross}
                  alt="cross"
                  onClick={() => {
                    dispatch(handleRightSideBar(!showRightSideBar));

                    // console.log("SideBar", showRightSideBar);
                  }}
                />
              </div>
              {/* <li>
              <a href="/profile">
                <FaRegUserCircle />
                <span>Profile</span>
              </a>
            </li> */}
              <li className="d-md-none d-block">
                <a href="/profile">
                  <img src={ProfileIcon1} alt="my bet" />
                  <span> Profile</span>
                </a>
                <a href="/gatewaylist">
                  <img src={DepositIcon1} alt="my bet" />
                  <span> Deposit</span>
                </a>
              </li>
              <li className="d-md-none d-block">
                <a href="/withdraw">
                  <img src={WithdrawIcon1} alt="my bet" />
                  <span> Withdraw</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={BetIcon} alt="my bet" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus" className="img_size">
                  <img src={BonusImg} />
                  <span className="bonus-space">Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral" className="img_size">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={AcStatement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>
              <li onClick={() => dispatch(logoutUser())}>
                <a href="#">
                  {/* <LogoutIcon /> */}
                  <img src={LogoutIconImg} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>

            <div className="theme-btn d-none">
              <button type="button" className="btn" onClick={toggleClass}>
                <img src={SunLight} alt="sun" className="light_theme" />
                <img src={MoonLight} alt="moon" className="dark_theme" />
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {showLeftSideBar && <MobLeftbar className="d-block d-md-none" />}
      </header>

      {
        <div className={isBottomHead ? `Bottom_head` : "d-none"}>
          <ul>
            {Array.isArray(headerItems) &&
              headerItems.map((item, index) => {
                return (
                  <li className="" key={index}>
                    <img
                      src={item?.image}
                      alt="AvitorImg"
                      className="d-md-none d-block"
                    />
                    <a
                      // href={item?.redirectionUrl}
                      onClick={() => {
                        navigate(item?.redirectionUrl, {
                          state: {
                            activeKey: item?.key,
                          },
                        });
                      }}
                      className={checkIfActive(item?.key) ? "active" : ""}
                    >
                      {item?.name}
                    </a>
                  </li>
                );
              })}
            {/* <li className="d-md-block d-none">
            <img src={HomeICon} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/sports"
              className={
                activePage == "/sports" || activePage === "/" ? "active" : ""
              }
            >
              Home
            </a>
          </li>
          <li>
            <img src={Inplay} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/sportsbook"
              className={activePage == "/sportsbook" ? "active" : ""}
            >
              In-Play
            </a>
          </li>
          <li>
            <img src={Cricket} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/sportsbook"
              className={activePage == "/sportsbook" ? "active" : ""}
            >
              Cricket
            </a>
          </li>
          <li>
            <img src={Soccer} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/sportsbook"
              className={activePage == "/sportsbook" ? "active" : ""}
            >
              Soccer
            </a>
          </li>
          <li>
            <img src={Tennis} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/sportsbook"
              className={activePage == "/sportsbook" ? "active" : ""}
            >
              Tennis
            </a>
          </li>
          <li>
            <img src={Horse} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/sportsbook"
              className={activePage == "/sportsbook" ? "active" : ""}
            >
              Horse
            </a>
          </li>
          <li>
            <img
              src={Greyhound}
              alt="AvitorImg"
              className="d-md-none d-block"
            />
            <a
              href="/sportsbook"
              className={activePage == "/sportsbook" ? "active" : ""}
            >
              Greyhound
            </a>
          </li>
          <li className="AviatorImg">
            <img src={AvitorImg} alt="AvitorImg" />
            <a
              href="/casino/spribe/aviator"
              className={activePage == "/casino/spribe/aviator" ? "active" : ""}
            >
              Aviator
            </a>
          </li>
          <li>
            <img
              src={EvolutionImg}
              alt="AvitorImg"
              className="d-md-none d-block"
            />
            <a
              href="/casino/evolution"
              className={activePage == "/casino/evolution" ? "active" : ""}
            >
              Evolution
            </a>
          </li>
          <li>
            <img src={LCImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/Casino"
              className={activePage == "/Casino" ? "active" : ""}
            >
              Live Casino
            </a>
          </li>

          <li>
            <img src={QtechImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/casino/qtech"
              className={activePage == "/casino/qtech" ? "active" : ""}
            >
              Qtech
            </a>
          </li>
          <li>
            <img
              src={SupernowaImg}
              alt="AvitorImg"
              className="d-md-none d-block"
            />
            <a
              href="/casino/supernowa"
              className={activePage == "/casino/supernowa" ? "active" : ""}
            >
              Supernowa
            </a>
          </li>
          <li>
            <img src={WCImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/casino/worldcasino"
              className={activePage == "/casino/worldcasino" ? "active" : ""}
            >
              World Casino
            </a>
          </li>
          <li>
            <img src={SlotsImg} alt="AvitorImg" className="d-md-none d-block" />
            <a href="/Slots" className={activePage == "/Slots" ? "active" : ""}>
              {" "}
              Slots
            </a>
          </li>
          <li>
            <img src={EzugiImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/casino/ezugi"
              className={activePage == "/casino/ezugi" ? "active" : ""}
            >
              {" "}
              Ezugi
            </a>
          </li> */}
          </ul>
        </div>
      }
    </>
  );
};

export default HeaderAfterLogin;
